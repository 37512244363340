/* ................................................ */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

p {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 30px;
  color: white;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
}

a {
  text-decoration: none !important;
}

.logo {
  height: 5rem !important;
}

.dropdown-toggle::after {
  color: white;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff !important;
}
.aboutHome {
  display: flex;
  align-items: center;
  text-align: justify;
}

.abouthome_paragraph {
  font-size: 1.2rem;
  color: black;
}

.aboutHome_div {
  border: 1px solid #f15c22 !important;
  border-radius: 50%;
}

.aboutHome_div:hover {
  background-color: #f15c22 !important;
  color: white !important;
}

.homeAboutImg h3 {
  color: #f15c22;
}

.card2 {
  background-color: #fd7a01 !important;
  border: 0 !important;
}

.card4Row {
  background-color: #fd7a01 !important;
}

.card4 {
  height: 7rem !important;
}

@media only screen and (max-width: 991px) {
  .navbar-toggler {
    transition: none;
    background-color: white !important;
    margin-top: auto;
    margin-bottom: auto;
  }

  .btnStyle {
    text-align: center;
  }

  .logo {
    height: 6rem !important;
  }

  .homeAboutImg img {
    width: 50% !important;
  }
  @media only screen and (max-width: 576px) {
    .homeAboutImg img {
      width: 75% !important;
    }
  }
  @media only screen and (max-width: 576px) {
    .wappIcons {
      width: 20% !important;
    }
  }
  @media only screen and (max-width: 576px) {
    .dropdown-toggle:after {
      color: #0e0101 !important;
    }
  }
  .homeAboutImg h3 {
    color: #f15c22;
    text-align: center;
  }

  .homeAboutImg h1 {
    text-align: center;
  }
  @media only screen and (max-width: 576px) {
    .cardFireImgs {
      height: 230px !important;
    }
  }
  @media only screen and (max-width: 576px) {
    .review_desc {
      line-height: 1.1 !important;
      text-align: initial !important;
    }
  }
  @media only screen and (max-width: 576px) {
    .reviewsCard {
      column-count: 2;
    }
  }

  .cardFireImgs {
    height: 185px;
  }
  @media only screen and (max-width: 576px) {
    .card2 img {
      width: 28% !important;
    }
    .card2 p {
      font-weight: 400;
      font-size: 1rem;
      /* line-height: 30px; */
      color: white;
    }
  }
  .card1 img {
    width: 50% !important;
  }

  .card2 img {
    width: 25% !important;
  }
}

.headertext {
  color: white !important;
}
.card-title:hover {
  color: #f15c22 !important;
}
.dropdown-menu .dropdown-item {
  padding: 0.5rem !important;
  color: black !important;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #ebd1d1 !important;
}

.menuText {
  color: #fff !important;
}
.menuText_a {
  color: #000 !important;
}
@media only screen and (max-width: 576px) {
  .menuText {
    color: #000 !important;
  }
}

.menuText a {
  color: #fff !important;
}

.headertext:focus {
  color: #ed5d2a !important;
  font-weight: 600 !important;
}

.headertext a {
  padding: 0 !important;
}

@media only screen and (max-width: 991px) {
  .headertext {
    color: rgb(15, 14, 14) !important;
  }
}

/* About */

.about_p {
  text-align: justify !important;
}

.aboutSection1 {
  background: #fa8326;
}

.aboutSection1 img {
  width: 80%;
}

@media only screen and (min-width: 1920px) {
  .aboutSection1 img {
    width: 50%;
  }
}
@media only screen and (max-width: 576px) {
  .aboutSection1 h1 {
    text-align: center;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .aboutSection2 h1 {
    text-align: center;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
}

.aboutSection1 p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
  color: white;
}

.about_p {
  text-align: justify !important;
}

.aboutSection2 {
  background: #202020;
}

.aboutSection2 img {
  width: 80%;
}
@media only screen and (min-width: 1920px) {
  .aboutSection2 img {
    width: 50%;
  }
}
.aboutSection2 p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
  color: white;
}

.visionMission h1 {
  background-color: #fa8326;
  color: white;
}

.visionMission p {
  text-align: justify !important;
  color: black;
}

.visionMission img {
  width: 50%;
  /* height: 17rem; */
}

@media only screen and (min-width: 576px) and (max-width: 1400px) {
  .visionMissionImg {
    margin-top: 7.5rem !important;
  }
}
@media only screen and (max-width: 576px) {
  .visionMission img {
    width: 75%;
    /* height: 17rem; */
  }
}
.footerLink {
  color: #ed5d2a;
}
.footerContact {
  width: 25% !important;
  bottom: 6%;
  left: 68%;
  border: 0 !important;
  position: absolute !important;
}
.footerLinks:hover {
  color: #ed5d2a !important;
}
.productTitle:hover {
  color: #ed5d2a !important;
}
@media only screen and (max-width: 576px) {
  .footerContact {
    width: 30% !important;
    left: 52rem;
    bottom: 2rem;
    position: absolute !important;
  }
  .footerContact a {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .footerContact {
    position: absolute !important;
    width: 100% !important;
    left: 0rem;
    bottom: -1rem;
    margin-bottom: 2rem;
  }
}
.footerCardHeader {
  font-weight: 800;
  font-size: x-large;
  text-align: center;
  background: #fd7a01 !important;
  color: white !important;
}

.ProductCard .productFooter {
  background-color: #414040;
}

.dieselProduct .productFooter {
  background-color: #f7c539;
}

.dieselProduct .productFooter h2 {
  color: black;
}

.dieselProduct .productFooter .modelDesc {
  color: green;
}

.dieselProduct .productFooter .modelId {
  color: white;
}

.ProductCard .productFooter h3 {
  color: #fa7b1d !important;
  font-size: 1.5rem;
}
.ProductCard .productFooter h3:hover {
  color: #fff !important;
}

.ProductCard .productFooter .modelId {
  color: black !important;
}

.ProductCard .productFooter .modelDesc {
  color: #fff;
}

.serviceCard {
  height: 12rem !important;
  box-shadow: 0 4px 16px 10px rgba(0.2, 0.2, 5, 0.1);
  background-color: #f26f12 !important;
}
.okkkk {
  padding-left: 70px;
}
.eng-im {
  position: absolute;
  left: 9%;
  z-index: 1;
  height: 200px;
  width: 200px;
}
/* .ookk{
  padding-left: 260px;
} */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .eng-im {
    /* position: absolute; */
    left: 2%;
    /* margin-top: 40px; */
    z-index: 1;
    height: 200px;
    width: 200px;
  }
  .serviceCard h4 {
    padding-left: 75px !important;
  }
}
.serviceCard h4 {
  background-color: #f26f12;
  color: white;
  padding-left: 110px;
}
@media only screen and (max-width: 576px) {
  .eng-im {
    /* position: absolute; */
    left: 9%;
    /* margin-top: 40px; */
    z-index: 1;
    height: 150px;
    width: 150px;
  }
  .serviceCard h4 {
    padding-left: 160px;
  }
}
.serviceCard h4 {
  background-color: #f26f12;
  color: white;
  padding-left: 110px;
}
@media only screen and (max-width: 480px) {
  .eng-im {
    /* position: absolute; */
    left: 10%;
    /* margin-top: 40px; */
    z-index: 1;
    height: 120px !important;
    width: 120px !important;
  }
  .serviceCard h4 {
    font-size: 21px !important;
    padding-left: 135px !important;
    padding-top: 1rem;
  }
}
@media only screen and (max-width: 350px) {
  .eng-im {
    /* position: absolute; */
    left: 10%;
    /* margin-top: 40px; */
    z-index: 1;
    height: 110px !important;
    width: 110px !important;
  }
  .serviceCard h4 {
    font-size: 18px !important;
    padding-left: 120px !important;
    padding-top: 1.2rem;
  }
}
.engs-im {
  height: 250px;
  width: 250px;
}

.subServiceCard {
  background-color: #a6a6a6 !important;
  box-shadow: 0 4px 16px 10px rgba(0.2, 0.2, 5, 0.1);
  border: 10px solid white !important;
}

.subServiceCard h4 {
  background-color: #a6a6a6;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: white;
  font-weight: bold;
}

.productImgs {
  width: 100% !important;
  padding: 1rem;
}

/* sample test */

/* td {
	line-height   : 35px;
	vertical-align: middle;
} */
/* .aButton {
	transition        : 1.0s ease all;
	-moz-transition   : 1.0s ease all;
	-webkit-transition: 1.0s ease all;

	border            : none;
	border-radius     : 3px;
	padding           : 0px 10px 0px 10px;

	color             : #0090d3;

	font-size         : 14px;

	text-decoration   : none;

	box-sizing        : border-box;
	-webkit-box-sizing: border-box;
	height            : 35px;
} */
/* .aButton:hover {
	transition        : 1.0s ease all;
	-moz-transition   : 1.0s ease all;
	-webkit-transition: 1.0s ease all;

	background-color  : #f07b29;
	color             : #fff !important;
} */

/* .boxShadow {
	box-shadow           : 5px 5px 5px #3f3f3f;
	-webkit-box-shadow   : 5px 5px 5px #3f3f3f;
}


.rotateX:hover {
	transform               : rotateX(360deg);
	-moz-transform          : rotateX(360deg);
	-webkit-transform       : rotateX(360deg);
	transform-origin        : center;
	-moz-transform-origin   : center;
	-webkit-transform-origin: center;

	transition              : transform 1.0s ease all;
	-moz-transition         : -moz-transform 1.0s ease all;
	-webkit-transition      : -webkit-transform 1.0s ease all;
} */

/* Home_About_page */

@media only screen and (max-width: 576px) {
  .btn-about {
    text-align: center;
  }
}
.hvr-underline-reveal:before {
  background: #f07b29 !important;
}

.hvr-underline-from-center:before {
  background: #f07b29 !important;
}

.marineSection {
  background-color: #333;
}
.bg-engine {
  background-color: #f26f12;
}
.align-im {
  display: flex;
  /* justify-content: end; */
  height: 300px;
}

.subServiceCard1 h4 {
  background-color: #a6a6a6;
  padding: 1.5rem;
  color: white;
  font-weight: bold;
}
